// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {

    apiKey: "AIzaSyD0IH2ZbfNoPCxw0FMerjT6IlQHi0ycBLo",

    authDomain: "dadd-6a33f.firebaseapp.com",

    projectId: "dadd-6a33f",

    storageBucket: "dadd-6a33f.firebasestorage.app",

    messagingSenderId: "1066956005485",

    appId: "1:1066956005485:web:8a2dcb2a66bc80ce5f5f64",

    measurementId: "G-Q9LW60Q945"

};


const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
