import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { FaCoins, FaCode, FaPercentage } from 'react-icons/fa';
import { FaThumbsUp } from 'react-icons/fa';
import { FaArrowDown } from 'react-icons/fa';
import './App.css';
import trumpVideo from './assets/trump.mp4';
import tikTokPic from './assets/tiktok_photo.png';
import bookPic from './assets/book.png';
import fromCar from './assets/fromCar.jpg';
import kissFlag from './assets/kissFlag.mp4';
import trumpCampaign from './assets/trumpCampaign.mp4';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';
import dexTools from './assets/dextools.png'
import dexScreener from './assets/dexscreener.png'
import { db } from './firebase'; // Import your Firestore instance
import { SiX, SiTelegram } from '@icons-pack/react-simple-icons'

// Define the `useIsMobile` hook outside of the `App` component
const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
};

const App = () => {
  const { ref, inView } = useInView({ triggerOnce: true });
  const [likes, setLikes] = useState(0);
  const isMobile = useIsMobile(); // Use the custom hook to check if it's mobile

  // Fetch likes from Firestore on component mount
  useEffect(() => {
    const fetchLikesFromDB = async () => {
      const docRef = doc(db, 'likes', 'likeCounter');
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setLikes(docSnap.data().count);
      } else {
        // If the document doesn't exist, initialize it
        await setDoc(docRef, { count: 0 });
      }
    };

    fetchLikesFromDB();
  }, []);

  const handleLike = async () => {
    const newLikeCount = likes + 1;
    setLikes(newLikeCount);

    // Update the like count in Firestore
    const docRef = doc(db, 'likes', 'likeCounter');
    await setDoc(docRef, { count: newLikeCount });
  };

  return (
    <div className="container">
      {/* First Section */}
      <div className="section">
        <div className="text-section">
          <h1>DADDY'S HOME</h1>
          <h3>#WEDIDITDONALD 2024</h3>
          <h4>Get The Token On: </h4>
          <div class="image-links">
            <div class="link-container">
              <a href="https://dexscreener.com/solana/Fi8RJ9iCh7GPUf4pRUjRbEH8EEURZfScTohCECL5Lz3V" target="_blank" rel="noopener noreferrer">
                <img src={dexScreener} alt="Dexscreener" class="circle-image"></img>
              </a>
              <h4 class="image-name">Dexscreener</h4>
            </div>
            <div class="link-container">
              <a href="https://www.dextools.io/app/en/ether/pair-explorer/0x1910e073709ee97ed0898273c4d58338a2a4ae94?t=1731334143405" target="_blank" rel="noopener noreferrer">
                <img src={dexTools} alt="Dextools" class="circle-image"></img>
              </a>
              <h4 class="image-name">Dextools</h4>
            </div>
          </div>
          <div className="social-icons">
            <a href="https://x.com/daddyshomecoin" target="_blank" rel="noopener noreferrer">
              <SiX className="social-icon" />
            </a>
            <a href="https://t.me/daddyshome24" target="_blank" rel="noopener noreferrer">
              <SiTelegram className="social-icon" />
            </a>
          </div>
        </div>
        <div className="video-section">
          <video autoPlay muted loop playsinline>
            <source src={trumpVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>

      {/* Tokenomics Section with Animation */}
      <div className={`bottomSection ${inView ? 'show' : ''}`} ref={ref}>
        <div className="video-section">
          <video controls autoPlay muted loop playsinline>
            <source src={trumpVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="tokenomics-section">
          <h1 className="tokenomics-title">TOKENOMICS</h1>
          <div>
            <div>
              <div className="tokenomics-block">
                <FaCoins className="icon" /> {/* Icon for Total Supply */}
                <div>
                  <h3>Total Supply</h3>
                  <p>1, 000, 000, 000</p>
                </div>
              </div>
              <div className="tokenomics-block">
                <FaCode className="icon" /> {/* Icon for Mint */}
                <div>
                  <h3>Mint</h3>
                  <p>REVOKED</p>
                </div>
              </div>
              <div className="tokenomics-block">
                <FaPercentage className="icon" /> {/* Icon for Tax */}
                <div>
                  <h3>Tax</h3>
                  <p>0/0</p>
                </div>
              </div>
              <div className="tokenomics-block">
                <FaCoins className="icon" /> {/* Icon for Total Supply */}
                <div>
                  <h3>TOKEN</h3>
                  <p>BURNED AND RENOUNCED</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Third Section with Conditional Rendering */}
      <div className="third-section">
        {isMobile ? (
          <div className="swiper-container">
            {/* Floating Button */}
            <a href="https://x.com/daddyshomecoin" target="_blank" rel="noopener noreferrer" className="floating-button">
              FOLLOW US ON <SiX className="icon" />
            </a>

            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              autoplay={{ delay: 2000 }}
              pagination={{ clickable: true }}
              modules={[Autoplay, Pagination]}
              className="mobile-carousel"
            >
              <SwiperSlide>
                <video autoPlay muted loop>
                  <source src={kissFlag} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </SwiperSlide>
              <SwiperSlide>
                <div className="image-wrapper">
                  <img src={tikTokPic} alt="TikTok" className="image" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <img src={bookPic} alt="Book" className="image" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="image-wrapper">
                  <img src={fromCar} alt="From Car" className="image" />
                  <div className="arrow-container">
                    <div className="moving-arrow">
                      <FaArrowDown className="arrow-icon" />
                      <h1 className="click-me-button">Click this</h1>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        ) : (
          <div className="image-section">
            <video autoPlay muted loop>
              <source src={kissFlag} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className="image-wrapper">
              <img src={tikTokPic} alt="TikTok" className="image" />
              <a href="https://x.com/ChartXchat" target="_blank" rel="noopener noreferrer" className="overlay-button">
                FOLLOW US ON <SiX className="icon" />
              </a>
            </div>
            <img src={bookPic} alt="Book" />
            <div className="image-wrapper">
              <img src={fromCar} alt="From Car" />
              <div className="arrow-container">
                <div className="moving-arrow">
                  <FaArrowDown className="arrow-icon" />
                  <h1 className="click-me-button">Click this</h1>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="text-section-third">
          <h1>HAVE U SEEN THIS TRUCK ?</h1>
          <div className="like-section">
            <div>
              <button className="like-button" onClick={handleLike}>
                <FaThumbsUp className="like-icon" />
              </button>
              <h2 className="like-count">YES: {likes}</h2>
            </div>
          </div>
        </div>
      </div>



      <div className="fourth-section">
        <video autoPlay muted loop playsinline>
          <source src={trumpCampaign} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="Footer">
        <h4>$DADDYSHOME TOKEN PAIR LINKS</h4>
        <div className="footer-links">
          <a href="https://dexscreener.com/solana/Fi8RJ9iCh7GPUf4pRUjRbEH8EEURZfScTohCECL5Lz3V" target="_blank" rel="noopener noreferrer">
            Dexscreener
          </a>
          <a href="https://www.dextools.io/app/en/ether/pair-explorer/0x1910e073709ee97ed0898273c4d58338a2a4ae94?t=1731334143405" target="_blank" rel="noopener noreferrer">
            Dextools
          </a>
        </div>
        <div className="footer-contact">
          <p className="email">
            Email: <a href="mailto:daddyshouse395@gmail.com">daddyshouse395@gmail.com</a>
          </p>
          <p className="token-contract">
            Token Contract: <span>BPF87Qje5ZkVPfCqUhZ2UbbyNse4bNNmUXRnMBShS3Je</span>
          </p>

        </div>
        <div className="social-icons">
          <a href="https://x.com/daddyshomecoin" target="_blank" rel="noopener noreferrer">
            <SiX className="social-icon" />
          </a>
          <a href="https://t.me/daddyshome24" target="_blank" rel="noopener noreferrer">
            <SiTelegram className="social-icon" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default App;
